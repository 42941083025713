@tailwind base;
@tailwind components;
@tailwind utilities;

/* Handle */
aside::-webkit-scrollbar {
    width: 3px;
}

aside::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
aside::-webkit-scrollbar-thumb:hover {
    background: #555;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

div::-webkit-scrollbar {
    width: 2px;
}

/* Track */
div::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
div::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
div::-webkit-scrollbar-thumb:hover {
    background: #555;
}

main::-webkit-scrollbar {
    width: 3px;
}

@font-face {
    font-family: 'Poppins';
    src: url('./assets/font/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('./assets/font/Poppins-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('./assets/font/Poppins-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Medium';
    src: url('./assets/font/Poppins-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Light';
    src: url('./assets/font/Poppins-Light.ttf') format('truetype');
}

@keyframes bounceLittle {
    0%, 100% {
        transform: translateY(-25%);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
        transform: translateY(0);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}

@keyframes bounceRight {
    0%, 100% {
        transform: translateX(-10%);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
        transform: translateX(0);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}

@keyframes bounceTop {
    0%, 100% {
        transform: translateY(25%);
        animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
        transform: translateY(0);
        animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
}

@keyframes pulseCustom {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
    }
}

.animation-pulseCustom {
    animation: pulseCustom 2s infinite;
}

.input {
    @apply border p-2 outline-none focus:border-dark-red transition-all duration-300 rounded-lg w-full;
}

.button {
    @apply w-fit py-2 px-4 bg-dark-red text-white font-medium hover:bg-darkRedTitle transition-all duration-300 rounded-lg;
}