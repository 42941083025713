.slider {
  -webkit-appearance: none;
  width: 50%;
  height: 5px;
  border-radius: 5px;
  background-color: #980000;
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #980000;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #0093E9;
  background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
  cursor: pointer;
}

input[type="range"]::-moz-range-progress {
  background-color: #43e5f7;
}
input[type="range"]::-moz-range-track {
  background-color: #9a905d;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 16px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 8px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #aaaaaa;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  border-radius: 8px;
}