.container {
  width: 20em;
  min-height: 50px;
  max-width: 300px;
  border: solid #BABABA;
  display: flex;
  align-items: center;
  gap: .5em;
  padding: .5em;
  outline: none;
  transition: all .2s ease-in-out;
}

.value {
  flex-grow: 1;
  display: flex;
  gap: .5em;
  overflow-x: clip;
}

.clear-btn {
  background: none;
  color: #BABABA;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0;
  font-size: 1.25em;
}

.clear-btn:focus,
.clear-btn:hover {
  color: #b64c4c;
}

.divider {
  background-color: #BABABA;
  align-self: stretch;
  width: .05em;
}

.caret {
  translate: 0 25%;
  border: .25em solid transparent;
  border-top-color: #BABABA;
}

.options {
  margin: 0;
  padding: 0;
  list-style: none;
  display: none;
  border-radius: 0.5rem;
  max-height: 15em;
  overflow-y: hidden;
  border: .05em solid #BABABA;
  width: 100%;
  left: 0;
  top: 50px;
  background-color: white;
  z-index: 100;
  transition: all .2s ease-in-out;
  position: absolute;
}

.options.show {
  display: block;
}

.option {
  padding: .25em .5em;
  cursor: pointer;
  transition: all .2s ease-in-out;
}

.option.selected {
  background-color: #BABABA;
}

.option.highlighted {
  background-color: #BABABA;
}

.option-badge {
  display: flex;
  align-items: center;
  padding: .15em .25em;
  gap: .25em;
  cursor: pointer;
  color: white;
  border-radius: 0.5rem;
  background-color: #980000;
  outline: none;
  transition: all .2s ease-in-out;
}

.option-badge:hover,
.option-badge:focus {
  background-color: #c64c4c;
}
