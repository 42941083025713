/* Input container */
.input-container {
    position: relative;
}

/* Input field */
.input-field {
    height: 5vh;
    display: block;
    width: 100%;
}

/* Input label */
.input-label {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 18px;
    color: rgba(204, 204, 204, 0);
    pointer-events: none;
    transition: all 0.3s ease;
}

.input-field:focus + .input-label {
    top: -20px;
    font-size: 12px;
    color: #980000;
}
